import React, { Fragment } from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import { ModalRoutingContext } from "gatsby-plugin-modal-routing"

import SEO from "../components/seo"
import {
  projectModal,
  projectModal__page,
  firstColumn,
  secondColumn,
  modal__closeIcon__link,
  modal__closeIcon__linkPage,
  modal__closeIcon__icon,
  project__top,
  project__top__info,
  project__top__title,
  project__top__year,
  project__top__badges,
  project__top__technologies__container,
  project__top__technologies__label,
  project__technologies__list,
  project__description,
  project__description__borderLeft,
  project__description__borderRight,
  project__tests,
  project__tests__text,
  project__tests__hr,
  project__links__label,
  project__links__links,
  project__links__link,
  badgeContainer,
  badgeLabel,
  imageStyle,
  technologyImageContainer,
} from "../sass/personal-project.module.scss"

const makeBadge = badge => (
  <div className={badgeContainer} key={badge[1]}>
    <span className={badgeLabel}>{`${badge[0]} :  `}</span>
    <img className={imageStyle} src={badge[1]} alt={badge[1]} />
  </div>
)

const PersonalProjectContent = ({
  modal,
  closeIcon,
  slug,
  title,
  brief,
  description,
  year,
  technologies,
  unitsTest,
  e2eTest,
  qaTest,
  accessibilityTest,
  modalImage,
  githubLink,
  deployedLink,
  badges,
}) => {
  const testsBools = [unitsTest, e2eTest, qaTest, accessibilityTest]
  const testNames = [
    "unit tests",
    "end-to-end tests",
    "quality assurance",
    "accessibility tests",
  ]
  const testsArray = []
  testsBools.forEach((v, i) => {
    if (v) {
      testsArray.push(testNames[i])
    }
  })
  let tests = testsArray.join(", ")
  if (testsArray.length > 1) {
    var lastComa = tests.lastIndexOf(",")
    tests = `${tests.substring(0, lastComa)} and ${tests.substring(
      lastComa + 2,
      tests.length
    )}`
  }

  return (
    <Fragment>
      <SEO
        title={`Guillaume Bouffard | Project: ${title}`}
        description={brief}
      />

      <div
        className={
          !!modal ? modal__closeIcon__link : modal__closeIcon__linkPage
        }
      >
        <Link to={`/personal-projects/#${slug}`}>
          <img
            className={modal__closeIcon__icon}
            alt="close-icon"
            src={closeIcon.file.url}
            title="Back to personal projects"
          />
        </Link>
      </div>

      <div className={!!modal ? projectModal : projectModal__page}>
        <div className={firstColumn}>
          <div className={project__top}>
            <div className={project__top__info}>
              <div>
                <div className={project__top__title}>{title.toUpperCase()}</div>
                <div className={project__top__year}>{year}</div>
              </div>

              {badges && (
                <div className={project__top__badges}>
                  {badges.map(badge => makeBadge(badge))}
                </div>
              )}
            </div>

            <div className={project__top__technologies__container}>
              <div className={project__top__technologies__label}>
                Project stack:
              </div>
              <div className={project__technologies__list}>
                {technologies.map(t => (
                  <div className={technologyImageContainer}>
                    <Img alt={t} fluid={t.fluid} title={t.title} />
                  </div>
                ))}
              </div>
            </div>

            <div className={project__tests}>
              <hr className={project__tests__hr} />
              <div
                className={project__tests__text}
              >{`Tested via: ${tests}`}</div>
            </div>
          </div>
          <div className={project__description}>
            <div className={project__description__borderLeft}></div>
            <div className={project__description__borderRight}></div>
            {description.description}
          </div>
        </div>

        <div className={secondColumn}>
          {modalImage && (
            <Img alt={`${title}-modal`} fluid={modalImage.fluid} />
          )}

          <div>
            <div className={project__links__label}>Links:</div>
            <div className={project__links__links}>
              <a
                className={project__links__link}
                href={githubLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                Code / Github
              </a>
              {deployedLink && (
                <a
                  className={project__links__link}
                  href={deployedLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Deployed url
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

const PersonalProjectModal = ({ data }) => {
  const project = data.contentfulPersonal
  /* Logic to handle badges as they cannot be passed as a Json in Contentful. Making an array of arrays the hard-way.
  badges must look like Travis : https..., Coveralls : https... with space:space and ,space needed to split */
  if (project.badges && project.badges.badges) {
    const badgesArray = []
    project.badges.badges
      .split(", ")
      .forEach(b => badgesArray.push(b.split(" : ")))
    project.badges = badgesArray
  }

  return (
    <ModalRoutingContext.Consumer>
      {({ modal }) => (
        <PersonalProjectContent
          modal={modal}
          closeIcon={data.contentfulAsset}
          {...project}
        />
      )}
    </ModalRoutingContext.Consumer>
  )
}

export default PersonalProjectModal

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulPersonal(slug: { eq: $slug }) {
      slug
      title
      brief
      description {
        description
      }
      year
      technologies {
        title
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      unitsTest
      e2eTest
      qaTest
      accessibilityTest
      modalImage {
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      githubLink
      deployedLink
      badges {
        badges
      }
    }
    contentfulAsset(title: { eq: "close-modal-icon" }) {
      file {
        url
      }
    }
  }
`
